
export const products = [
  '',
  "Baczewski",
  "Holba",
  "Zubr",
  "Litovel Premium",
  "Litovel Dark",
  "Litovel Citron",
  "Desperados",
  "Vermont IPA",
  "Cherry sour",
  "Double IPA",
  "Piwo bezglutenowe",
  "Litovel Pszeniczne",
  "Żywiec Biały",
  "Heineken 00",
  "Cieszyn Pszeniczne",
  "Holba Free",
  "Dzik",
  "Spirytus 95%",
  "Wyborowa",
  "Soplica Śliwkowa",
  "Soplica Kawowa",
  "Soplica Pigwowa",
  "Soplica Malinowa",
  "Soplica wiśniowa",
  "Soplica orzech laskowy", 
  "Żubrówka Bison Grass",
  "Żołądkowa Gorzka",
  "Żołądkowa Miętowa",
  "Becherovka",
  "Jagermeister",
  "Kahlua",
  "Seagrams",
  "Bombay",
  "Capitan Morgan Dark",
  "Capitan Morgan Spiced",
  "Havana Club",
  "Johnny Walker",
  "Jack Daniels",
  "Jim Beam",
  "Jameson",
  "Talisker",
  "Wild Turky",
  "Stock 84",
  "Pliska",
  "Proper Twelve",
  "Ballantines",
  "Baileys",
  "Macallan",
  "Maker's Mark",
  "Malibu",
  "Sierra Silver",
  "Sierra Reposado",
  "Śliwowica Passover",
  "Cabernet Savinione",
  "Chardonnay",
  "Martini Bianco",
  "Martini Dry",
  "Martini Rosato", 
  "Coca cola",
  "Cola Zero",
  "Tonik",
  "Sprite",
  "Fanta",
  "Red bull",
  "Red bull light",
  "Sok pamarańczowy",
  "Sok grejfrutowy",
  "Sok jabłkowy",
  "Sok porzeczkowy",
  "Sok pomidorowy",
  "Woda Kinga niegazowana",
  "Woda Kinga gazowana",
  "Kawa",
  "Paluszki",
  "Chipsy",
  "Tabasco",
];

export const productType = [
  "",
  "Wódka",
  "Gin",
  "Tequila",
  "Whisky",
  "Brandy",
  "Burbon",
  "Rum",
  "Likier",
  "Spirytus",
  "Piwo",
  "Cydr",
  "Wino",
  "Wermut",
  "Napój bezalkoholowy",
  "Przekąska",
];

export const packages = [
  "",
  "butelka 0.5 L",
  "butelka 0.7 L",
  "butelka 0.75 L",
  "butelka 1 L",
  "butelka 0.4 L",
  "butelka 0.33 L",
  "butelka 0.3 L",
  "butelka 0.25 L",
  "puszka 0.5 L",
  "puszka 0.33 L",
  "puszka 0.25 L",
  "puszka 0.2 L",
  "kega 30 L",
  "sztuka",
  "opakowanie"
];