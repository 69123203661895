import React from 'react';

const Header = () => {
    return (
        <header>
            <h2>Inwentaryzacja Pub Propaganda 31.03.2024</h2>
            <h2>Lem-Son Sp. z o.o.</h2>
            <hr />
        </header>
      );
}
 
export default Header;